import { IconButton, Toolbar, Typography, Box } from '@mui/material'

import { Icons } from '../../../assets'

const styles = {
  root: {
    minHeight: 3.375,
    height: 'fit-content',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: 'neutral.light',
    boxShadow: 4,
    p: 0,
    py: 'var(--spacing-s)',
  },
  content: {
    width: { xs: '100%', md: 'var(--container-max-width)' },
    mx: { xs: 0, md: 'auto' },
    display: 'flex',
    columnGap: 1,
  },
  backButton: {
    m: 0,
    p: 0,
    height: 'var(--size-buttonM)',
    width: 'var(--size-buttonM)',
    color: 'neutral.darkest',
    '& > svg': {
      height: 'var(--size-iconM)',
      width: 'var(--size-iconM)',
    },
  },
  title: {
    mr: 1.25,
  },
}

const NavigationSecondary = ({ id, title, onClickGoBack }) => {
  return (
    <Box sx={styles.root} id={id}>
      <Toolbar disableGutters sx={styles.content}>
        <IconButton
          aria-label='volver atrás'
          onClick={onClickGoBack}
          sx={styles.backButton}
        >
          <Icons.ChevronLeft />
        </IconButton>
        <Typography component='h2' variant='h5' sx={styles.title}>
          {title}
        </Typography>
      </Toolbar>
    </Box>
  )
}

export default NavigationSecondary
