import { useLayoutEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { useTexts } from '../../../texts'
import { User, Public } from '../../../business'
import ScrollRevisionOnPageChange from '../../ScrollRevisionOnPageChange'
import NavigationSecondary from './NavigationSecondary'

const styles = {
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: 'neutral.lightest',
  },
  main: {
    flexGrow: 2,
    width: 'min(100%, var(--containers-contentLMaxWidth))',
    mx: 'auto',
  },
}

const TITLE_PATH_MAP = {
  'top-up-balance': (texts) => texts.getBalanceTopUpLabel(),
  'bank-card-edition': (texts) => texts.getBankCardEditLabel(),
  'bank-card-introduction': (texts) => texts.getBankCardAddLabel(),
  'update-personal-data': (texts) => texts.updatePersonalData(),
  'update-password': (texts) => texts.updatePassword(),
}

const NAVIGATION_ID = 'secondary-navigation'
const CONTENT_ID = 'main-secondary-page'

const getTitleOnLocation = (location, texts, sections, params) => {
  if (location.pathname.includes('section')) {
    if (!!sections?.data) {
      return sections.data[params.sectionId]
    }
  } else if (location.pathname.includes('topic')) {
    const label = location?.state?.title
    return label ?? decodeURIComponent(params.topic)
  } else {
    const pathName = Object.keys(TITLE_PATH_MAP).find((path) =>
      location.pathname.includes(path),
    )
    if (pathName) {
      return TITLE_PATH_MAP[pathName](texts)
    }
  }
  return ''
}

const SecondaryPageLayoutView = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const texts = useTexts()
  const sections = Public.useArticlesSections()

  const title = getTitleOnLocation(location, texts, sections, params)

  const handleGoBack = () => {
    navigate(`/${location.pathname.split('/')[1]}`)
  }

  useLayoutEffect(() => {
    const secondaryNavHeight = document
      .getElementById(NAVIGATION_ID)
      ?.getBoundingClientRect()?.height

    if (Boolean(secondaryNavHeight)) {
      document.getElementById(CONTENT_ID).style.marginTop =
        secondaryNavHeight.toString() + 'px'
    }
  }, [])

  return (
    <ScrollRevisionOnPageChange>
      <Box sx={styles.root}>
        <NavigationSecondary
          id={NAVIGATION_ID}
          title={title}
          onClickGoBack={handleGoBack}
        />
        <Box component='main' sx={styles.main} id={CONTENT_ID}>
          {children}
        </Box>
      </Box>
    </ScrollRevisionOnPageChange>
  )
}

const SecondaryPageLayout = ({ children }) => {
  return (
    <User.PersonalDataProvider>
      <User.BalanceProvider>
        <User.BankCardProvider>
          <SecondaryPageLayoutView>{children}</SecondaryPageLayoutView>
        </User.BankCardProvider>
      </User.BalanceProvider>
    </User.PersonalDataProvider>
  )
}

export default SecondaryPageLayout
